/* istanbul ignore file */
import React from "react";
import { createRoot } from 'react-dom/client';

import AdminRoutes from "./routes";

import '@amzn/awsui-global-styles/polaris.css';
import AuthLoading from "./common/AuthLoading/AuthLoading";
import moment from "moment-timezone";

const container = document.getElementById("app");
const root = createRoot(container!);
moment.tz.setDefault('Etc/UTC');

export const MainApp = () => (
        <AuthLoading>
                <AdminRoutes />
        </AuthLoading>
);

root.render(<MainApp />)
