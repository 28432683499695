import {
  Box,
  Button,
  Link,
  SpaceBetween,
} from '@amzn/awsui-components-react';
const stringPath = "orderDetails.tabs.messages";

import './MessageComponent.scss';
import { UserPermissions } from 'services/user-permissions';
import { Comment } from '@amzn/ito-client';
import { buildDateTimeLocal, getTimestampString } from 'common/utils/time-utils';
import { useState } from 'react';

interface MessageComponentProps {
  comment: Comment
}

enum DATE_FORMAT {
  DATE = "date",
  TIME = "time"
}

export const MessageComponent = (props: MessageComponentProps) => {
  const [dateType, setDateType] = useState<String>(DATE_FORMAT.DATE);

  const userPhonetool = props.comment.posted?.by ? `https://phonetool.amazon.com/users/${props.comment.posted?.by}` : ``;
  function getMessageType() {
      if(props.comment.posted?.by === UserPermissions.instance.username){
          return "user-message"
      } else if(props.comment.tags?.includes("procurement")) {
          return "procurement-message"
      }
      else {
          return "other-message"
      }
  }

  function getFirstTwoLeters(name: string | undefined): string {
    if(name){
      return name.substring(0, 2).toUpperCase();
    }
    else {
      return "??"
    }
  }

  const convertLinksToElements = (text: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlRegex);
    
    return parts.map((part, i) => {
      if (part.match(urlRegex)) {
        return <Link key={i} href={part} target='_blank'>{part}</Link>;
      }
      return part;
    });
  };

  return (
        <div className={getMessageType()} >
          <SpaceBetween size="xs" direction="vertical">
                <SpaceBetween size="xs" direction="horizontal">
                  <span className="user-initials" data-testid="user-icon">{getFirstTwoLeters(props.comment.posted?.by)}</span>
                  <Box variant="h5" className='name' padding={{ top: "xs", bottom:"xxs" }} data-testid="name"><Link href={userPhonetool} target='_blank'>{props.comment.posted?.by}</Link></Box>
                  <Box variant="p" color="text-body-secondary" padding={{ top: "xs", bottom:"xxs" }} data-testid="time"><span onDoubleClick={() => setDateType(dateType === DATE_FORMAT.DATE ? DATE_FORMAT.TIME : DATE_FORMAT.DATE)}>{dateType === DATE_FORMAT.DATE ? buildDateTimeLocal(props.comment.posted?.on) : getTimestampString(props.comment.posted?.on)}</span></Box>
              </ SpaceBetween>
            <Box variant="p" data-testid="message" className='message'>
              {props.comment.comment && convertLinksToElements(props.comment.comment)}
            </Box>
          </SpaceBetween>
        </div>
  );
};
