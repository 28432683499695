import { isArray } from "lodash";
import jp from 'jsonpath';
import { ManagerBasicInformation, Order, OrderItem } from "@amzn/ito-client";
import { buildDateOnly, buildDateTimeLocal, buildSelectOptionsFromStrings, buildStatusLabel, getStatusSelectMapping, getString } from "common";
import { CustomViewAndEditFormField } from "common/CustomViewAndEditFormField/CustomViewAndEditFormField";
import { Badge, Box, BoxProps, FormField, Icon, IconProps, Input, InputProps, Link, Select, SelectProps, Textarea } from "@amzn/awsui-components-react";
import moment from "moment-timezone";
import { NonCancelableCustomEvent } from "@amzn/awsui-components-react/polaris/internal/events";
import { buildingsData, financialCodes } from "data";
import { useState } from "react";

interface FormFieldConfig {
    id: string,
    selector: string | string[],
    displayType: string,
    editable: boolean,
    editType?: string
}

interface FinancialCodeOption {
    label: string;
    value: string;
    description: string;
}

type ValidationFunction = (value: string) => { isValid: boolean, errorMessage: string };

export const ALPHANUMERIC_REGEXP = /^[a-zA-Z0-9]*$/;
export const ALPHANUMERIC_WITH_DASH_REGEXP = /^[a-zA-Z0-9-]*$/;

export function buildRenderedFormFields<T>(
    orderFieldsConfig: FormFieldConfig[],
    order: Order,
    isEditMode: boolean,
    stringPath: string,
    formValues: T,
    handleInputChange: (field: keyof T, value: string | string[]) => void,
    procurementMembers: string[],
    setHasValidationErrors: (hasErrors: boolean) => void
): { [id: string]: JSX.Element } {
    var renderedFormFields: { [id: string]: JSX.Element } = {};

    for (const field of orderFieldsConfig) {
        let value: any = null;
        let values: any[] = [];
        // Get value
        if (isArray(field.selector)) {
            // Multiple values
            value = '';
            values = [];
            for (const selector of field.selector) {
                const val = jp.value(order, selector as string);
                value += ' ' + val;
                values.push(val as string);
            }
        } else {
            // Single value
            value = jp.value(order, field.selector as string);
        }

        // Remove "$.", "$.name" --> "name";
        const fieldKeyInTheForm = field.selector.slice(2) as keyof T;

        const label = getString(`${stringPath}.${field.id}`);
        const viewModeComponent = getDisplayType<T>(field.displayType, value, values, fieldKeyInTheForm, formValues, handleInputChange, procurementMembers, setHasValidationErrors);

        let editModeComponent;
        if (field.editable) {
            editModeComponent = getDisplayType<T>(field.editType, value, values, fieldKeyInTheForm, formValues, handleInputChange, procurementMembers, setHasValidationErrors);
        }

        // Store the render component in a dictionary and index it by id
        renderedFormFields[field.id] =
            <CustomViewAndEditFormField
                label={label} // Title of the field
                viewDisplay={viewModeComponent} // JSX component shown in view mode
                editDisplay={editModeComponent} // JSX component shown in edit mode
                editable={field.editable} // If the field can be edited
                editMode={isEditMode} // Flag to change modes
            />;
    }

    return renderedFormFields;
};

function getDisplayType<T>(
    type: string | undefined,
    value: any,
    values: any[],
    fieldKey: keyof T,
    formValues: T,
    handleChange: (field: keyof T, value: string | string[]) => void,
    procurementMembers: string[],
    setHasValidationErrors: (hasErrors: boolean) => void,
): JSX.Element {
    const componentType = type ?? "";
    switch (componentType) {
        case "statusLabel":
            return buildStatusLabel(value);
        case "requestBadge":
            return buildRequestBadge(value);
        case "categoriesAsText":
            return buildCategories(value);
        case "orderItemsQuantity":
            return buildItemsQuantity(value);
        case "datetime_local":
            return buildDateTimeLocal(value);
        case "expediteWithDate":
            return buildExpeditedWithDate(values);
        case "ageWithColor":
            return buildAgeWithColor(value);
        case "requesterlinkToMaple":
            return buildRequesterName(values);
        case "managerName":
            return buildManagerName(value);
        case "text-newline":
            return buildTextNewLine(value);
        case "orderStatusSelect":
            return buildSelectForOrderStatus<T>(formValues, fieldKey, handleChange);
        case "buildingSelect":
            return buildSelectForBuildings<T>(formValues, fieldKey, handleChange);
        case "assigneeSelect":
            return buildSelectForAssignees<T>(formValues, fieldKey, handleChange, procurementMembers);
        case "financialCodeSelect":
            return buildSelectForFinancialCodes<T>(formValues, fieldKey, handleChange);
        case "textInput":
            return buildInputTextEdit<T>(formValues, fieldKey, handleChange, "text");
        case "numericInput":
            return buildInputTextEdit<T>(formValues, fieldKey, handleChange, "number");
        case "textArea":
            return buildTextAreaEdit<T>(formValues, fieldKey, handleChange);
        case "companyCodeInput":
            return buildInputTextEditWithCustomConstraint<T>(formValues, fieldKey, handleChange, setHasValidationErrors, validate2or4Chars, "text");
        case "locationCodeInput":
            return buildInputTextEditWithCustomConstraint<T>(formValues, fieldKey, handleChange, setHasValidationErrors, validate4or5Chars, "number");
        case "text":
        default:
            return <div>{value ?? "-"}</div>;
    }
}

// Editable fields
export function buildInputTextEdit<T>(formValues: T, fieldId: keyof T, handleChange: (field: keyof T, value: string) => void, type: InputProps.Type) {
    return (
        <Input
            type={type}
            value={formValues[fieldId] as string}
            onChange={({ detail }) => {
                // Check if the new value matches the alphanumeric pattern
                if (ALPHANUMERIC_REGEXP.test(detail.value)) {
                    handleChange(fieldId, detail.value);
                }
            }}
        />
    );
}

export function buildTextAreaEdit<T>(formValues: T, fieldId: keyof T, handleChange: (field: keyof T, value: string) => void) {
    return (
        <Textarea value={formValues[fieldId] as string} onChange={({ detail }) => handleChange(fieldId, detail.value)} />
    )
}

export function buildInputTextEditWithCustomConstraint<T>(
    formValues: T,
    fieldId: keyof T,
    handleChange: (field: keyof T, value: string) => void,
    setHasValidationErrors: (hasErrors: boolean) => void,
    validate: ValidationFunction,
    type: InputProps.Type
) {
    const [errorText, setErrorText] = useState<string>("");

    const validateInput = (value: string) => {
        const { isValid, errorMessage } = validate(value);
        if (isValid) {
            setErrorText("");
            setHasValidationErrors(false);
        } else {
            setErrorText(errorMessage);
            setHasValidationErrors(true);
        }
    };

    return (
        <FormField errorText={errorText}>
            <Input
                onChange={({ detail }) => {
                    if (ALPHANUMERIC_REGEXP.test(detail.value)) {
                        validateInput(detail.value);
                        handleChange(fieldId, detail.value);
                    }
                }}
                value={formValues[fieldId] as string}
                type={type}
            />
        </FormField>
    );
}

export const validate2or4Chars = (value: string) => {
    if (value.length === 2 || value.length === 4) {
        return { isValid: true, errorMessage: "" };
    } else {
        return { isValid: false, errorMessage: "Code must be exactly 2 or 4 characters long." };
    }
};

export const validate4or5Chars = (value: string) => {
    if (value.length === 4 || value.length === 5) {
        return { isValid: true, errorMessage: "" };
    } else {
        return { isValid: false, errorMessage: "Code must be a number exactly 4 or 5 digits long." };
    }
};

export function buildSelectForBuildings<T>(
    formValues: T,
    fieldId: keyof T,
    handleChange: (field: keyof T, value: string, flag?: string) => void
) {
    const options = buildSelectOptionsFromStrings(buildingsData.map(b => b.building));
    const currentValue = formValues[fieldId] as string ?? "-";

    return (
        <Select
            expandToViewport
            filteringType="auto"
            selectedOption={{ label: currentValue, value: currentValue }}
            options={options}
            onChange={(event) => {
                const selectedBuilding = event.detail.selectedOption.value ?? "-";
                handleChange(fieldId, selectedBuilding, "buildingChange");
            }}
        />
    );
}

export function buildSelectForAssignees<T>(formValues: T, fieldId: keyof T, handleChange: (field: keyof T, value: string) => void, procurementMembers: string[]) {
    const options = buildSelectOptionsFromStrings(procurementMembers);
    const currentValue = formValues[fieldId] as string ?? "-";

    return (
        <Select
            filteringType="auto"
            selectedOption={{ label: currentValue, value: currentValue }}
            options={options}
            onChange={(detail) => onChangeSelectEvent(detail, fieldId, handleChange)}
        />
    )
}

function buildFinancialCodeOptions(codes: Record<string, string>): FinancialCodeOption[] {
    return Object.keys(codes).map((code) => ({
        value: code,
        label: code,
        description: codes[code]
    }));
}

export function buildSelectForFinancialCodes<T>(formValues: T, fieldId: keyof T, handleChange: (field: keyof T, value: string) => void) {
    const options = buildFinancialCodeOptions(financialCodes);
    const currentValue = formValues[fieldId] as string ?? "-";

    return (
        <Select
            expandToViewport
            filteringType="auto"
            selectedOption={{ label: currentValue, value: currentValue }}
            options={options}
            onChange={(detail) => onChangeSelectEvent(detail, fieldId, handleChange)}
        />
    );
}

export function buildSelectForOrderStatus<T>(formValues: T, fieldId: keyof T, handleChange: (field: keyof T, value: string) => void) {
    const firstLetterUppercase = (word: string) =>
        word.replace(/\w+/g, (w: string) => w[0].toUpperCase() + w.slice(1).toLowerCase());
    const replaceUnderscore = (word: string) => word.replace(/_/g, " ");

    const STATUS_MAPPING_SELECT = getStatusSelectMapping();
    const options: SelectProps.Option[] =
        Object.keys(STATUS_MAPPING_SELECT).map((status) => {
            return {
                label: replaceUnderscore(firstLetterUppercase(status)),
                value: status,
                iconName: STATUS_MAPPING_SELECT[status]
            } as SelectProps.Option
        })

    const currentValue = formValues[fieldId] as string ?? "-";
    const lowerCaseStatus = currentValue ? (currentValue as string).toLowerCase() : "Unable to retrieve status";

    return (
        <Select
            selectedOption={{ label: firstLetterUppercase(replaceUnderscore(lowerCaseStatus)), value: lowerCaseStatus }}
            options={options}
            onChange={(detail) => onChangeSelectEvent(detail, fieldId, handleChange)}
            data-testid="update-order-status-select"
        />
    )
}

function onChangeSelectEvent<T>(
    event: NonCancelableCustomEvent<SelectProps.ChangeDetail>,
    fieldId: keyof T,
    handleChange: (field: keyof T, value: string) => void
) {
    const newValue = event.detail.selectedOption.value;
    if (newValue) {
        return handleChange(fieldId, newValue)
    };
};

// View fields
export const buildItemsQuantity = (items: OrderItem[] | undefined) => {
    let totalQuantity = 0;
    if (items) {
        items.map(item => totalQuantity += item.quantity ?? 0);
    }

    return (<div>{totalQuantity}</div>);
}

export function buildManagerName(managerInfo: ManagerBasicInformation | undefined) {
    if (!managerInfo) {
        return <div>-</div>;
    }
    const managerAlias = managerInfo.username ? `(${managerInfo.username})` : ``;
    return buildLinkToMaple(`${managerInfo.firstName} ${managerInfo.lastName} ${managerAlias}`, managerInfo.username);
}

export function buildCategories(value: string | string[] | undefined) {
    const categories = Array.isArray(value) ? value.join(", ") : "-";
    return (<div>{categories}</div>);
}

export function buildRequesterName(values: any[]) {
    const requesterFirstName = values[0] ?? "";
    const requesterLastName = values[1] ?? "";
    const requestedBy = values[2] ?? "";
    return buildLinkToMaple(`${requesterFirstName} ${requesterLastName}`, requestedBy)
}

export function buildLinkToMaple(displayName: string, userAlias: string | undefined) {
    return (
        <Link external href={`https://maple-admin-prod.corp.amazon.com/my-support/person/${userAlias ?? ""}`} target={"_blank"}>{displayName}</Link>
    )
};

export function buildRequestBadge(value: string | undefined) {
    const color = value?.toLowerCase() === "standard" ? "blue" : "red";
    return (
        <Badge color={color}>{value}</Badge>
    )
}

export function buildExpeditedWithDate(values: any[]) {
    const isExpedite = values[0];
    const expediteDate = values[1];

    const content = isExpedite === true && expediteDate ? <Box>Yes. {buildDateOnly(expediteDate)}</Box> : "No";
    return (<Box>{content}</Box>);
}

export function buildAgeWithColor(value: any) {
    var now = moment(new Date());
    var requestedDate = moment(value as string);
    var durationInDays = moment.duration(now.diff(requestedDate)).asDays();

    interface AgeWithColorSettings {
        textColor: BoxProps.Color,
        iconName: IconProps.Name | undefined,
        iconVariant: IconProps.Variant,
    };

    const categories: { [id: string]: AgeWithColorSettings } = {
        "regular": { textColor: "text-label", iconName: undefined, iconVariant: "normal" },
        "warning": { textColor: "text-status-warning", iconName: "flag", iconVariant: "warning" },
        "error": { textColor: "text-status-error", iconName: "status-warning", iconVariant: "error" }
    };

    const categoryKey = durationInDays > 40 ? "error" : durationInDays > 5 ? "warning" : "regular";
    const category = categories[categoryKey];

    return (
        <Box color={category.textColor} variant="p">
            {category.iconName &&
                <><Icon name={category.iconName} variant={category.iconVariant} />{` `}</>}
            {`${Math.floor(durationInDays)} days ago`}
        </Box>
    );
}

function buildTextNewLine(value: string) {
    if (!value) {
        return <div>-</div>;
    }
    var lines: string[] = value.split("\n");
    return (
        <div>
            {lines.map((line) => <p>{line}</p>)}
        </div>
    );
}