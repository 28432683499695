import { useEffect, useState } from "react";
import { GetOrders_AllCommandOutput, Order, Relation } from "@amzn/ito-client";
import { useGetOrder, useGetOrders } from "hooks";
import { Header, Table } from "@amzn/awsui-components-react";

import { getString } from "common/ui-string-labels/ui-strings-utils";
import EmptyTableBox from "common/EmptyTableBox/EmptyTableBox";
import { ColumnSetting } from "common/utils/config-models";

import ordersTableConfig from './relations.table-config.json';
import { buildColumnDefinitions } from "common/utils";
import { TaxonomyIdOrder } from "@amzn/taxonomy-lib";
import * as _ from "underscore";
import { getITOClient } from "hooks/hooks-helpers";

const columns: ColumnSetting[] = ordersTableConfig.columns;

const stringPath = "orderDetails.tabs.relations";

interface RelationsTabProps {
    order: Order;
}

interface RelationWithOrder extends Relation {
    order: Order;
}

const columnDefinitions = buildColumnDefinitions(columns, undefined, false);

export const RelationsTab = (props: RelationsTabProps) => {

    const [relatedOrders, setRelatedOrders] = useState<RelationWithOrder[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    let relations: Relation[] = [];

    if (props.order.relations) {
        relations = props.order.relations;
    }

    // To get orders from ITO
    useEffect(() => {
        if (!relations)
            return;

        const perConsumerOrders = _.groupBy(relations, o => {
            const orderTaxId = new TaxonomyIdOrder(o.id!);
            return orderTaxId.consumer ?? "_";
        });

        for (const key in perConsumerOrders) {
            if (Object.prototype.hasOwnProperty.call(perConsumerOrders, key)) {
                const thisConsumerOrders: Relation[] = perConsumerOrders[key];

                // Client
                const { client, requestHandler } = getITOClient();
                if (key !== "_") {
                    requestHandler.pushHeader("x-consumer", key);
                }

                client.getOrders_All({
                    filters: {
                        "orderId": thisConsumerOrders.map(relation => relation.id!)
                    }
                }, (err: any, data?: GetOrders_AllCommandOutput) => {
                    data?.orders?.forEach(o => {
                        const relation = relations.find(r => r.id === o.orderId);
                        relatedOrders.push({
                            ...relation,
                            order: o
                        });
                    });
                    setRelatedOrders(relatedOrders);
                    setIsLoading(false);
                });
            }
        }
    }, [relations]);

    return (
        <Table
            data-testid="items-requested-table"
            items={relatedOrders}
            columnDefinitions={columnDefinitions}
            resizableColumns={true}
            empty={<EmptyTableBox />}
            loading={isLoading}
            loadingText="Loading resources"
            variant="borderless"
            wrapLines={true}
            contentDensity="comfortable"
            header={
                <Header
                    data-testid="items-requested-table-header"
                    variant="awsui-h1-sticky"
                    counter={`(${relatedOrders.length})`}
                    description={getString(`${stringPath}.table.headerDescription`)}
                >
                    {getString(`${stringPath}.table.headerTitle`)}
                </Header>
            }
        />
    );
};
