import { useEffect, useState } from "react";
import { SpaceBetween, Button, Textarea } from "@amzn/awsui-components-react";
import { CSRFService } from "services/csrf-service";
import { GetCommentsCommandOutput, Order } from "@amzn/ito-client";
import { GetCommentsResult, useGetComments, usePostComment } from "hooks";

import { useFlashBarItemsActions } from "common/UseFlashBarItems/useFlashBarItems";
import { getString } from "common/ui-string-labels/ui-strings-utils";
import { debounce } from "lodash";
import { MessageList } from "./Messages/MessagesList";
import { useOrderEditActions, useOrderEditItems } from "views/OrdersDetailsPage/useOrderEdit";

const stringPath = "orderDetails.tabs.messages";
const FIVE_MINUTES_IN_MS = 300000;
const HALF_SECOND_IN_MS = 500;

interface MessagesProps {
    order: Order;
    useOrderEditItems: useOrderEditItems;
    useOrderEditActions: useOrderEditActions;
    flashBarItemsActions: useFlashBarItemsActions;
    comments?: GetCommentsCommandOutput;
    setDefaultComments?: React.Dispatch<React.SetStateAction<GetCommentsResult>>;
    inputComment: string;
    setInputComment: (value: string) => void;
}

export const MessagesTab = (props: MessagesProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const { comments, isLoading: isLoadingComments, doGetComments } = useGetComments(props.comments);
    const { doPostComment, error: errorPostComment, isLoading: isLoadingPost, response } = usePostComment();

    useEffect(() => {
        if (response && !errorPostComment) {
            // If adding message was successful, flag the order
            props.useOrderEditActions.flagOrderWithFirstEdit();
            doGetComments({ taxonomyId: props.order.orderId!, filters: {}});
        }
    }, [response, errorPostComment]);

    // Auto refresh
    useEffect(() => {
        const interval = setInterval(() => {
            doGetComments({ taxonomyId: props.order.orderId!, filters: {} });
        }, FIVE_MINUTES_IN_MS);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        setIsLoading(isLoadingComments || isLoadingPost);
    }, [isLoadingComments, isLoadingPost]);

    useEffect(() => {
        if(response && props.setDefaultComments) {
            props.setDefaultComments(comments as GetCommentsCommandOutput)
        }
    }, [comments])

    const debouncedSendComment = debounce(sendComment, HALF_SECOND_IN_MS);
    function handleSendComment() {
        setIsLoading(true);
        props.setInputComment("");
        debouncedSendComment();
    }

    async function sendComment() {
        const csrfService = new CSRFService();
        const csrfToken = await csrfService.getToken();
        doPostComment(props.order.orderId!, props.inputComment, csrfToken, []);
    }

    return (
        <SpaceBetween size="xs">
            <SpaceBetween size="xl">{<MessageList comments={comments} />}</SpaceBetween>
            {!props.useOrderEditItems.isReadOnly &&
                <>
                    <Textarea
                        onChange={(event) => props.setInputComment(event.detail.value)}
                        rows={12}
                        value={props.inputComment}
                        disabled={isLoading}
                        data-testid="order-comment-input"
                    />
                    <Button
                        variant="primary"
                        onClick={handleSendComment}
                        loading={isLoading}
                        disabled={isLoading || !props.inputComment}
                        data-testid="order-send-comment"
                    >
                        {getString(`${stringPath}.sendButton`)}
                    </Button>
                </>
            }
        </SpaceBetween>
    );
};
