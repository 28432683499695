import { PropertyFilterOperator } from '@amzn/awsui-collection-hooks';
import {
  Box,
  CollectionPreferencesProps,
  DatePicker,
  Icon,
  Input,
  MultiselectProps,
  Popover,
  PropertyFilterProps,
  Select,
  SelectProps,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import Link from '@amzn/awsui-components-react/polaris/link';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import CopyText from 'common/CopyText/CopyText';
import { ITMPV1_WEBSITE_URL, SYSTEM_FULFILL_NAME } from 'configuration/config';
import jp from 'jsonpath';
import { isArray } from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { Link as LinkReactRouter } from 'react-router-dom';
import CheckboxItem from '../../common/CheckboxItem/CheckboxItem';
import { ColumnSetting, VisibleOption } from './config-models';
import Badge from "@amzn/awsui-components-react/polaris/badge";
import StatusIndicator, { StatusIndicatorProps } from '@amzn/awsui-components-react/polaris/status-indicator';
import { regionsData, tableUtilsData } from "data";
import { OrderItem, OrderStatusStamp, OrderSubLineItem } from '@amzn/ito-client';
import { buildDate, buildDateOnly, buildDateTimeLocal } from './time-utils';
import { ALPHANUMERIC_REGEXP, ALPHANUMERIC_WITH_DASH_REGEXP, buildAgeWithColor } from 'common/utils/form-utils';
import { getString } from 'common/ui-string-labels/ui-strings-utils';
import { FilesService } from 'services/files-service';

export interface MappingData {
  statusMapping: Record<string, string>;
  tagsMapping: Record<string, string>;
}

export interface SortingState {
  sortBy?: string;
  sortOrder?: string;
}

export const defaultPagingOptions = [
  {
    label: '30',
    value: 30
  },
  {
    label: '50',
    value: 50
  },
  {
    label: '70',
    value: 70
  }
];

const STANDARD = "standard";

function getValueOrValues(item: any, selector: Array<any> | string) {
  let value: any = null;
  let values: string[] = [];
  if (isArray(selector)) {
    // Multiple values
    value = '';
    values = [];
    for (const eachSelector of selector) {
      const val = jp.value(item, eachSelector as string);
      value += ' ' + val;
      values.push(val as string);
    }
  } else {
    // Single value
    // Uses jsonpath to get the value
    value = jp.value(item, selector as string);
  }
  return { value, values };
}

export const validateRequiredField = (item: any, value: string | undefined): string | undefined => {
  if (!value || value.trim() === "") {
    return "This field is required.";
  }
  return undefined;
};

export function buildColumnDefinitions(
  columnSettings: ColumnSetting[],
  onlyThese?: readonly string[],
  editEnabled?: boolean,
): TableProps.ColumnDefinition<any>[] {
  const columnDefinitions: TableProps.ColumnDefinition<any>[] = [];
  for (const column of columnSettings) {
    const showIt: boolean = checkIfShowIt(column, onlyThese);
    if (!showIt) {
      continue;
    }
    const cellFunction = (item: any): React.ReactNode => {
      let id: any = null;
      let url = "";
      const { value, values } = getValueOrValues(item, column.selector);

      // Set display based on field type...
      switch (column.type) {
        case 'date':
          return buildDate(value);
        case 'date_only':
          return buildDateOnly(value);
        case 'date_utc':
          if (value) {
            const dateUtc = moment(value as string)
              .utc()
              .format('MM/DD/YYYY');
            return <span title={value}>{dateUtc}</span>;
          }
          break;

        case 'datetime_utc':
          if (value) {
            const local = moment(value as string)
              .utc()
              .format('MM/DD/YYYY HH:mm:ss');
            return <span title={value}>{local}</span>;
          }
          break;

        case 'datetime_local':
          return buildDateTimeLocal(value);

        case 'assignee_alias':
          return buildAssigneeAlias(value as string);

        case 'requester_info':
          return buildRequesterInfo(values);

        case 'requestedBy_info':
          return buildRequestedByInfo(value);

        case 'selectme':
          return <CheckboxItem key={value} name="orderId" id={id}></CheckboxItem>;

        case 'image_url':
          return buildImageUrl(value as string, column.props);

        case 'link':
          if (column.linkSetting) {
            id = encodeURIComponent(jp.value(item, column.linkSetting.idSelector as string));
            url = column.linkSetting.url.replace('{id}', id);
          }
          return (
            <LinkReactRouter target="_blank" to={url} state={{ id, value }}>
              {value}
            </LinkReactRouter>
          );

        case 'tag':
          return buildOrderTags(value);

        case 'external_link':
          return (
            <Link
              external
              href={value}
              variant="secondary"
            >
              {value}
            </Link>
          );

        case 'status':
          return buildStatusLabel(value);

        case 'lineStatusLabel':
          return buildLineStatusLabel(value);

        case 'categories':
          if (value) {
            return value.join(', ');
          }
          break;

        case 'last_updated_date':
          if (value) {
            return buildLastUpdate(value);
          }
          break;

        case 'request_type':
          return buildRequestType(value);

        case "lineItemDeliveredQuantityCalculation":
          return buildLineItemQuantityDeliveredCalculation(value);

        case "lineItemRemainingQuantityCalculation":
          return buildLineItemQuantityRemainingCalculation(values);

        case "ageWithColor":
          return buildAgeWithColor(value);
        case "legacy_order_link":
          return buildLinkToLegacyOrder(values);

        case "pr_number_list":
          return buildSublineItemFieldList(value, "prNumber", "No PR numbers available");

        case "po_number_list":
          return buildSublineItemFieldList(value, "poNumber", "No PO numbers available");
        
        case "splitPanelSKU":
          return buildSplitPanelSKU(values);

        case "bulkDownloadOriginalFile":
          return buildBulkDownloadOriginalFile(value);

        case "bulkDownloadResultsFile":
          return buildBulkDownloadResultsFile(value);

        // Calculate the count of all items from the order
        case 'total_quantity':
          if (value) {
            const total = value.reduce((total: number, item: OrderItem) => total + (item.quantity ?? 0), 0);

            return <span title={total}>{total}</span>;
          }
          break;

        default:
          return <span>{value}</span>;
      }
    };

    const editFunction = (item: any, props: TableProps.CellContext<any>): React.ReactNode => {
      if (!column.editType) {
        return undefined;
      }

      const { value, values } = getValueOrValues(item, column.selector);
    
      // Initialize currentValue with the existing value if it's undefined and if it is one of the required fields
      if (props.currentValue === undefined && (column.id === "vendor" || column.id === "prNumber")) {
        props.setValue(value);
      }
    
      switch (column.editType) {
        case "allCharactersInput":
          return buildInput(value, props.currentValue, props.setValue);
        case "alphanumericInput":
          return buildInput(value, props.currentValue, props.setValue, ALPHANUMERIC_REGEXP);
        case "alphanumericWithDashInput":
          return buildInput(value, props.currentValue, props.setValue, ALPHANUMERIC_WITH_DASH_REGEXP);
        case "numericInput":
          return buildNumericInput(value, props.currentValue, props.setValue);
        case "itemsStatusSelect":
          return buildSelectForLineItemsStatus(value, props.currentValue, props.setValue);
        case "datePicker":
          return buildDatePicker(value, props.currentValue, props.setValue);
        default:
          return undefined;
      }
    };    

    columnDefinitions.push({
      ...column,
      cell: cellFunction,
      editConfig: editEnabled && column.editType ? {
        editingCell: editFunction,
        validation: column.editConfig?.validation === "validateRequiredField" ? validateRequiredField : undefined,
      } : undefined,
      sortingField: column.sortable ? column.id : undefined
    });
  }
  return columnDefinitions;
}

// Request type
function buildRequestType(value: string): React.ReactNode {
  if (value) {
    const lowercaseValue = value.toLowerCase();
    if (lowercaseValue === 'standard') {
      return (<Badge color="blue">{value}</Badge>);
    } else if (lowercaseValue === 'nonstandard') {
      return (<Badge color="red">{value}</Badge>);
    }
  }
  return null;
}

// User Alias
function buildRequesterInfo(values: string[]): React.ReactNode {
  const userAlias = values[0] ?? "alias unavailable";
  const userEmail = values[1] ?? "email unavailable";

  return (
    <SpaceBetween size='xs'>
      <Link external href={`https://maple-admin-prod.corp.amazon.com/my-support/person/${userAlias}`} target={"_blank"}>@{userAlias}</Link>
      <CopyText textContent={userEmail} copyContent={userEmail} isHtml={false} />
    </SpaceBetween>
  );
}

function buildRequestedByInfo(value: string | undefined): React.ReactNode {
  const userAlias = value ?? "alias unavailable";

  return (
    <Link external href={`https://maple-admin-prod.corp.amazon.com/my-support/person/${userAlias}`} target={"_blank"}>@{userAlias}</Link>
  );
}

// Assignee Alias
function buildAssigneeAlias(value?: string): React.ReactNode {
  if (value) {
    return <Box variant={'p'}> {value !== SYSTEM_FULFILL_NAME ? `@${value}` : value} </Box>;
  }

  return (
    <Box color={'text-body-secondary'} variant={'p'}>
      Unassigned
    </Box>
  );
}

type Color = "green" | "blue" | "red" | "grey";
type TagKeys = 'ITSE' | 'Amazonian' | 'FTE';

// Initialize TAGS_MAPPING with known mappings
const TAGS_MAPPING: Record<TagKeys, Color> = Object.keys(tableUtilsData.table_utils.tagsMapping).reduce((acc, key) => {
  acc[key as TagKeys] = tableUtilsData.table_utils.tagsMapping[key as TagKeys] as Color;
  return acc;
}, {} as Record<TagKeys, Color>);

// Build a badge for a given label value, defaulting to grey for unspecified tags
export function buildTag(value: string): React.ReactElement {
  const tagColor = TAGS_MAPPING[value as TagKeys] || 'grey';
  return <Badge color={tagColor} key={value}>{value}</Badge>;
}

// Function to render all tags for the orderLabels field in a single cell
export function buildOrderTags(orderLabels?: { label: string; description: string; on: string; via: string }[]): React.ReactElement {
  if (!orderLabels) {
    return <div />;
  }

  return (
    <div>
      {orderLabels.map(orderLabel => buildTag(orderLabel.label))}
    </div>
  );
}


// Image
interface ImageProps {
  width: string;
}
function buildImageUrl(theUrl: string, props: ImageProps): React.ReactNode {
  return <img src={theUrl} width={props.width} alt="" />;
}

///////////////////
export function buildFilteringProperties(columnSettings: ColumnSetting[]): PropertyFilterProps.FilteringProperty[] {
  const filters: PropertyFilterProps.FilteringProperty[] = [];
  for (const column of columnSettings) {
    if (!column.operators && !column.defaultOperator) continue;
    filters.push({
      key: column.id,
      propertyLabel: column.header,
      operators: column.operators ? (column.operators as PropertyFilterOperator[]) : undefined,
      defaultOperator: column.defaultOperator ? (column.defaultOperator as PropertyFilterOperator) : undefined,
      groupValuesLabel: column.header
    });
  }
  return filters;
}

/**
 * Prepares the options for the preferences, to set visible columns
 * @param columnSettings The settings from the json file
 * @returns The array of options for visible content
 */
export function buildVisibleContentOptions(
  columnSettings: ColumnSetting[]
): CollectionPreferencesProps.VisibleContentOption[] {
  const options: CollectionPreferencesProps.VisibleContentOption[] = [];
  for (const column of columnSettings) {
    if (column.visible === VisibleOption.never) continue;

    options.push({
      id: column.id,
      label: column.header,
      editable: column.visible !== VisibleOption.always
    });
  }
  return options;
}

/**
 * Returns Cloudscape Select Options from an Array of strings
 * @param array The list of strings you want as options
 * @returns The options for the Select
 */
export function buildSelectOptionsFromStrings(array: string[]) {
  const selectOptions: SelectProps.Option[] = array.map(stringValue => {
    return { label: stringValue, value: stringValue } as SelectProps.Option;
  });
  return selectOptions;
}

/**
 * Maps the regions.json data into MultiSelect option groups
 * @returns Countries grouped by regions to use in the MultiSelect Cloudscape Component
 */
export function buildMultiSelectLocationOptions() {
  const selectOptions: MultiselectProps.OptionGroup[] = regionsData.map(region => {
    const countries = region.countries.map(country => {
      return { id: country, label: country, value: country } as MultiselectProps.Option;
    });
    return { label: region.region, options: countries } as MultiselectProps.OptionGroup;
  });
  return selectOptions;
}

function buildLineItemQuantityDeliveredCalculation(value: OrderSubLineItem[]) {
  if (!value) {
    return "-";
  }

  let total = 0;
  value.map((sublineItem) => total += sublineItem.quantityDelivered ?? 0);
  return total;
}

function buildLineItemQuantityRemainingCalculation(values: any[]) {
  const sublineItems = values[0] as OrderSubLineItem[];
  const totalQuantityRequested = values[1] as number;

  if (!sublineItems || !totalQuantityRequested) {
    return "-";
  }

  let totalDelivered = 0;
  sublineItems.map((sublineItem) => totalDelivered += sublineItem.quantityDelivered ?? 0);

  const remainingQuantity = totalQuantityRequested - totalDelivered;
  return remainingQuantity;
}

function buildSublineItemFieldList(value: any[], key: keyof OrderSubLineItem, isUndefinedMessage: string): string {
  const sublineItems = value as OrderSubLineItem[];

  if (!sublineItems) {
    return isUndefinedMessage;
  }

  const listOfNumbers: string[] = sublineItems
    .map((sublineItem) => sublineItem[key]?.toString() ?? "")
    .filter((number) => number.trim() !== ""); // Filter out empty strings

  return listOfNumbers.join(', ');
}

function buildSplitPanelSKU(values: any[]) {
  // Comes from .itemInformation.sku
  const standardItemSKU = values[0];

  // Comes from .details.sku
  const nonStandardItemSKU = values[1];

  let skuToDisplay = nonStandardItemSKU ?? standardItemSKU;
  return <span>{skuToDisplay}</span>;
}

// Inline editing
export function buildInput(value: any, formValue: any, setFormValue: (value: any) => void, regexpValidation?: RegExp) {
  return (
    <Input
      value={formValue === undefined ? value : formValue}
      onChange={({ detail }) => {
        if (!regexpValidation || regexpValidation.test(detail.value)) {
          setFormValue(detail.value)
        }
      }}
    />
  );
}

export function buildNumericInput(value: any, formValue: any, setFormValue: (value: any) => void) {
  return (
    <Input
      type="number"
      value={formValue === undefined ? value : formValue}
      onChange={({ detail }) => {
        const value = detail.value === '' ? 0 : parseInt(detail.value, 10);
        if (value >= 0) {
          setFormValue(detail.value)
        }
      }}
    />
  );
}

export function buildDatePicker(value: any, formValue: any, setFormValue: (value: any) => void) {
  return (
    <DatePicker
      expandToViewport
      onChange={({ detail }) => setFormValue(moment(detail.value).toDate())}
      value={moment((formValue ?? value ?? new Date()).toISOString()).format("YYYY-MM-DD")}
      placeholder="YYYY/MM/DD"
    />
  )
}

export function buildSelectForLineItemsStatus(value: any, formValue: any, setFormValue: (value: any) => void) {
  // Replace "_" with whitespace and capitalize first words
  const getDisplayName = (word: string) =>
    word
    .replace( // Replace "_"
      /_/g, " "
    )
    .replace( // Capitalize
      /\w+/g, (w: string) => w[0].toUpperCase() + w.slice(1).toLowerCase()
    );

  const statusMappingSelect = getOrderLineItemStatusSelectMapping();
  const options: SelectProps.Option[] =
    Object.keys(statusMappingSelect).map((status) => {
      return {
        label: getDisplayName(status),
        value: status,
        iconName: statusMappingSelect[status]
      } as SelectProps.Option
    });

  return (
    <Select
      autoFocus={true}
      expandToViewport={true}
      selectedOption={{ label: getDisplayName(formValue ?? value), value: formValue ?? value }}
      options={options}
      onChange={(event) => setFormValue(event.detail.selectedOption.value)}
    />
  )
}

function checkIfShowIt(column: ColumnSetting, onlyThese: readonly string[] | undefined): boolean {
  if (column.visible === VisibleOption.never) {
    return false;
  }
  if (column.visible === VisibleOption.always) {
    return true;
  }
  if (!onlyThese) {
    if (column.visible === VisibleOption.no) {
      // Hidden by default
      return false;
    }

    // Visible by default
    return true;
  } else {
    // Check based on selected preferences
    return onlyThese.indexOf(column.id) >= 0;
  }
}

// Status
export function buildStatusLabel(status: string | undefined) {
  const orderStatusMapping: { [key: string]: string } = tableUtilsData.table_utils.statusMapping;
  return buildStatusIndicator(status, orderStatusMapping);
}

export function buildLineStatusLabel(status: string | undefined) {
  const lineStatusMapping: { [key: string]: string } = tableUtilsData.table_utils.lineItemStatusMapping;
  return buildStatusIndicator(status, lineStatusMapping);
}

function buildStatusIndicator(status: string | undefined, mapping: { [key: string]: string }) {
  const lowerCaseStatus = status ? (status as string).toLowerCase().replace(/_/g, " ") : "Unable to retrieve status";
  const statusType = status ? mapping[status] as StatusIndicatorProps.Type : undefined;
  return (
    <span className="status">
      <StatusIndicator type={statusType ?? "error"}>{lowerCaseStatus}</StatusIndicator>
    </span>
  );
}

function buildLinkToLegacyOrder(values: any) {
  const legacyId = values[0];

  if(!legacyId || legacyId.length === 0){
    return <></>; // No legacy, show empty
  }

  const firstEdit = values[1];
  const legacyLink = `${ITMPV1_WEBSITE_URL}procurement/hardware_orders/${legacyId}/edit`;
  const isBlocked = firstEdit && firstEdit !== "ham";
  return (
    <SpaceBetween size="xs" direction="horizontal">
      <Link external href={legacyLink}>{legacyId}</Link>
      {isBlocked &&
      <Popover dismissButton={false} position="top" content={getString("common.readOnlyMessage")}>
        <StatusIndicator type="stopped" colorOverride="yellow"/>
      </Popover>}
    </SpaceBetween>
  )
};

function buildLastUpdate(value: OrderStatusStamp[]): React.ReactNode {
  const validEntries = value.length != 0 ? value.filter(entry => entry.on !== undefined) : [];

  if (validEntries.length === 0) {
    return (<div></div>);
  }

  const lastUpdateDate = validEntries.reduce((latest, entry) => {
    return entry.on && latest.on && new Date(entry.on) > new Date(latest.on) ? entry : latest;
  }).on;

  return buildDateOnly(lastUpdateDate);
}

function buildBulkDownloadOriginalFile(
  value: string
): React.ReactNode {

  return (<SpaceBetween direction="horizontal" size="xs">
    <Icon name="download" variant="link" />
    <Link
      data-testid="download-file-original"
      href="#"
      onFollow={() => FilesService.instance.downloadBulkImportFile(encodeURI(value), "original")}
    >
      {value}
    </Link>
  </SpaceBetween>
  );
}

function buildBulkDownloadResultsFile(
  value: string
): React.ReactNode {

  if (value)
    return (<SpaceBetween direction="horizontal" size="xs">
      <Icon name="download" variant="link" />
      <Link
        data-testid="download-file-results"
        href="#"
        onFollow={() => FilesService.instance.downloadBulkImportFile(encodeURI(value.replace("results/", "")), "results")}
      >
        {getString("imports.download")}
      </Link>
    </SpaceBetween>
    );

  return (<SpaceBetween direction="horizontal" size="xs">
    <Icon name="status-positive" variant='success' />
    {getString("imports.allRowsProcessed")}
  </SpaceBetween>);
}

export function getStatusSelectMapping() {
  const statusMappingForSelect: { [key: string]: string } = tableUtilsData.table_utils.statusSelectMapping;
  return statusMappingForSelect;
}

export function getOrderLineItemStatusSelectMapping() {
  const statusMappingForSelect: { [key: string]: string } = tableUtilsData.table_utils.lineItemSelectStatusMapping;
  return statusMappingForSelect;
}